.success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #00D214; /* Green background for success */
    color: #fff; /* White text color */
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
  
  .success-check {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  
  .success-message {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-bottom: 30px;
  }
  
  .success-description {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .success-description.attention {
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .success-button {
    background-color: #fff; /* White background for the button */
    color: #00D214; /* Green text color */
    font-weight: bold;
    font-size: large;
    border: none;
    padding: 15px 20px;
    border-radius: 50px;
    cursor: pointer;
    width: 90%;
    max-width: 300px;
    margin-top: 30px;
    margin-bottom: 150px;
  }
  
  .success-button:hover {
    background-color: #e0e0e0; /* Light grey background on hover */
  }
  
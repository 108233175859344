.container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alinha o conteúdo ao topo */
  min-height: 100vh;
  background-color: #f2f2f2;
  padding-top: 30px; /* Adiciona um pouco de espaço no topo */
}

.content {
  background-color: #fff;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  text-align: center;
  margin-top: 0px;
}

.content.error {
  background-color: #ffdddd;
}

.header {
  margin-top: 10px;
}

.logo {
  width: 100px;
}

.order-info {
  margin-bottom: 10px;
}

.order-info h3 {
  font-weight: bold;
  font-size: large;
  margin-top: 10px;
}

.order-info h4 {
  font-size: 12px;
  margin-top: 0;
  color: gray;
}

.order-info p {
  font-size: 14px;
  margin: 0;
  color: gray;
}

.order-info h2 {
  font-size: 18px;
  margin-top: 20;
}

.status {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
}

.txt-aguarde {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: -10px; /* Ajusta a margem entre a animação e o texto */
  margin-top: -10px;
  margin-bottom: -20px;
}

.text-pag-pix {
  color: #00C1A2;
  font-weight: bold;
  font-size: large;
  margin-top: 20px;
}

.h3div {
  color: #00C1A2;
  font-weight: bold;
  font-size: large;
  margin-top: 20px;
}

.qr-code {
  width: 150px; /* Define a largura fixa */
  height: 150px; /* Define a altura fixa */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0; /* Espaçamento vertical */
  position: relative;
  margin: 0 auto;
  margin-top: -10px;
}

.qr-image {
  max-width: 100%;
  max-height: 100%;
  position: absolute; /* Para garantir que a imagem ocupa o mesmo espaço que a animação */
}

.copy-button {
  background-color: #00C1A2;
  color: #fff;
  font-weight: bold;
  font-size: large;
  border: none;
  padding: 15px 20px;
  border-radius: 50px;
  cursor: pointer;
  width: 90%;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.copy-button:hover {
  background-color: #00A794;
}

.copy-button.copied {
  background-color: #354D83; /* Verde para indicar sucesso */
}

.icon {
  margin-right: 8px; /* Espaçamento entre o ícone e o texto */
  font-size: 15px; /* Ajusta o tamanho do ícone */
}

.instructions {
  font-size: 14px;
  color: #555;
}

.distribuicaoTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 0px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.distribuicao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.infoDistribuidor {
  text-align: left;
  flex: 1;
  font-size: 12px;
  color: gray;
}

.infoPagador {
  text-align: right;
  flex: 1;
  font-size: 12px;
  color: gray;
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #EC0000; /* Red background for error */
  color: #fff; /* White text color */
  flex-direction: column;
  text-align: center;
  padding: 20px;

}

.error-check {
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
  margin-top: -100px;
}

.error-message {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.error-description {
  font-size: 16px;
  margin-bottom: 30px;
}

.error-button {
  background-color: #fff; /* White background for the button */
  color: #EC0000; /* Red text color */
  font-weight: bold;
  font-size: large;
  border: none;
  padding: 15px 20px;
  border-radius: 50px;
  cursor: pointer;
  width: 90%;
  max-width: 300px;
  margin-top: 60px;
  margin-bottom: 100px;
}

.error-button:hover {
  background-color: #e0e0e0; /* Light grey background on hover */
}
